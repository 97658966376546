<template>
    <div class="view col-12 table-responsive py-5">
        <loader-component v-if="loading" />
        <data-table class="col-12 table table-striped tabe-bordered display" :data="clients" :columns="columns"
            :options="{ responsive: true, autoWidth: false, language: 
                {
                    search: 'Pesquisa', zeroRecords: 'Sem clientes para exibir',
                    info: 'Exibindo _START_ - _END_ de _TOTAL_ clientes',
                    infoEmpty: '',
                    infoFiltered: '(filtrados de _MAX_ clientes)',
                    lengthMenu: 'Exibir _MENU_ cadastros',
                    paginate: {first: 'Primeira', last: '&Uacute;ltimo', next: 'Próxima', previous: 'Anterior'}
                }
            }"
        >
            <thead>
                <tr class="table-primary">
                    <th>#</th>
                    <th>Nome</th>
                    <th>Sobrenome</th>
                    <th>Endere&ccedil;o</th>
                    <th>Ver mais</th>
                </tr>
            </thead>
        </data-table>
    </div>
</template>

<script>
    import Api from '@/helpers/Api.js'
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)
    // DataTable.use(pdfmake)

    export default {
        name: "ClientsView",
        components: {
            DataTable,
            LoaderComponent
        },
        data() {
            return {
                loading: true,
                clients: [],
                columns: [
                    {data: 'id'},
                    {data: 'name'},
                    {data: 'last_name'},
                    {data: 'address'},
                    {data: null, render: (data) => `<a href='/client/${data.id}' class='btn bnt-primary text-primary'><i class='fa fa-eye'></i></a>`},
                ]
            }
        },

        created() {
            document.title = "Clientes"
            this.getClients()
        },

        methods: {
            async getClients() {
                try {
                    this.loading = true
                    const response = await Api.get("customers");
                    this.clients = response.data.data
                } catch(e) {
                    alert("Error: \n" + e.data.error)
                } finally {
                    this.loading = false
                }     
            }
        }
    }
</script>

<style scoped>
    @import 'datatables.net-bs5';
</style>