<template>
    <div class="card col-12 p-0" :class="['card-' + card]">
        <div class="card-header text-left">
            {{ title }}
        </div>

        <form  @submit.prevent="submit" autocomplete="off" class="m-0 p-0">
            <div class="card-body text-left d-flex flex-wrap justify-content-start">
                <slot></slot>
            </div>

            <div class="card-footer text-left mr-md-3 col-12 col-md-auto m-0">
                <button class="btn" :class="['btn-' + card]">
                    {{ action }}
                </button>

                <button class="btn ml-md-3 col-12 col-md-auto" :class="['btn-' + action2.class]" v-if="action2" type="button" @click="secondAction">
                    {{ action2.text }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "CardComponent",
        props: {
            action: String,
            title: String,
            card: String,
            action2: Object
        },
        emits: ['form-submited', 'second-action'],
        methods: {
            submit() {
                this.$emit('formSubmited')
            },
            secondAction() {
                this.$emit('secondAction')
            }
        }
    }
</script>