<template>
    <footer class="main-footer">
        <strong>180<sup><u>o</u></sup> Solu&ccedil;&otilde;es</strong>
        Todos os direitos reservados.
    </footer>
</template>

<script>
    export default {
        name: "FooterComponet",   
    }
</script>