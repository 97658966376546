<template>
    <card-component @form-submited="putData" @second-action="deleteUser" :title="user.name" action="Atualizar dados" :action2="{text: 'Eliminar', class: 'danger'}" card="primary">
        <loader-component v-show="loading" />
        <form-group-component v-for="input in inputs" :key="input.model" :label="input.label" :class="[input.class, input.model]">
            <input :type="input.type" v-model="user[input.model]" class="form-control new-user-input" 
                :placeholder="input.placeholder"  :id="['input-'+input.model]" :name="input.model" :readonly="input.readOnly">
        </form-group-component>
        <form-group-component class="col-md-3" label="Certificado pfx">
            <div class="custom-file">
                <input type="file" class="custom-file-input" id="input-cert_file" @change="changeFile" autocomplete="off">
                <label class="custom-file-label">Selecionar</label>
            </div>
        </form-group-component>
        <form-group-component class="col-md-4" label="Senha do certificado">
            <input type="password" v-model="user.cert_password" class="form-control" id="input-cert_password" autocomplete="off">
        </form-group-component>

        <form-group-component class="col-md-5" label="permissoes">
            <select2 v-model="user.permissions" :options="permissions" :settings="{theme: 'default', multiple: 'multiple'}" id="input-permissions" style="width: 100%"></select2>
        </form-group-component>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import Api from '@/helpers/Api.js'
    import Select2 from 'vue3-select2-component';
    import $ from 'jquery'

    export default {
        name: "NewColaboratorView",
        data() {
            return {
                off: 'off',
                loading: false,
                permissions: ['upload', 'edit', 'delete', 'send_request', 'protect'],
                user: {
                    name: '-',
                    last_name: '-',
                    email: '-',
                    phone: '-',
                    password: '-',
                    cert_file: null,
                    cert_password: null,
                    permissions: []
                },
                inputs: [
                    {type: 'text', label: 'Nome', model: 'name', class: 'col-md-7', placeholder: 'Nome do usuario'},
                    {type: 'text', label: 'Sobrenome', model: 'last_name', class: 'col-md-5', placeholder: 'Sobrenome'},
                    {type: 'email', label: 'E-mail', model: 'email', class: 'col-md-4', placeholder: 'E-mail', readOnly: true},
                    {type: 'string', label: 'Celular', model: 'phone', class: 'col-md-4', placeholder: 'Celular'},
                    {type: 'password', label: 'Senha', model: 'password', class: 'col-md-4', placeholder: 'Senha'},
                ]
            }
        },
        created() {
            document.title = "Colaborador"
            this.getUser()
        },
        mounted() {
        },
        components: {
            CardComponent,
            FormGroupComponent,
            LoaderComponent,
            Select2
        },
        methods: {
            async getUser() {
                try {
                    this.loading = true
                    const response = await Api.get("teams?id=" + this.$route.params.id);
                    if (response.data.data.length > 0) {
                        this.user = response.data.data[0]
                    } else {
                        alert("Usuario nao encontrado")
                        this.$router.push("/colaborators")
                    }
                } catch(e) {
                    alert("Error: \n" + e.data.error)
                    this.$router.push("/colaborators")
                } finally {
                    this.loading = false
                }  
            },
            changeFile(event) {
                this.user.cert_file = event.target.files[0];
            },
            async putData() {
                try {
                    this.loading = true
                    $('.new-client-input').removeClass('is-invalid')
                    const formData = new FormData();
                    formData.append("tt", "llll")
                    for(let data in this.user) {
                        if (data == 'permissions') {
                            this.user[data].forEach((el, i) => {
                                formData.append(`permissions[${i}]`, el)
                            })
                        } else {
                            formData.append(data, this.user[data]);
                        }
                    }
                    if (this.cert_file) {
                        formData.append('cert_file', this.cert_file, this.cert_file.name)
                    }
                    const response = await Api.post("teams/" + this.user.email, formData, true);
                    // this.companies = response
                    alert(response.data.message)
                    this.$router.push("/colaborators")
                } catch(e) {
                    let alerta = e.data.error ? e.data.error:e.data.message
                    alert("Error: \n" + alerta)
                    if (e.data.errors) {
                        let message = "";
                        for(let prop in e.data.errors) {
                            $(`#input-${prop}`).addClass('is-invalid')
                            message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                        }
                        alert(message)
                    }
                } finally {
                    this.loading = false
                }     
            },
            async deleteUser() {
                const del = confirm("Deseja eliminar o usuario?")
                if (del) {
                    this.loading = true
                    try {
                        const res = await Api.delete(`teams?email=${this.user["email"]}`)
                        alert(res.data.message)
                    } catch(e) {
                        let alerta = e.data.error ? e.data.error:e.data.message
                        alert(alerta)
                    } finally {
                        this.loading = false
                        this.$router.push("/colaborators")
                    }
                }
            }
        }
    }
</script>