<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <div class="main-content d-flex row flex-wrap p-0 m-0">
            <slot></slot>
        </div>
    </div>
    <!-- ./Content Wrapper. Contains page content -->
</template>

<script>
    export default {
        name: "MainContent",
        data() {return {}},
        props: {
            titlePage: String
        }
    }
</script>