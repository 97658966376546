<template>
    <div class="wrapper">
        <preloader-component :logo="logo"/>
        <nav-bar :list="navList1" @search="seacrh" />

        <main-side-bar home_url="/" text="PlugSign" :logo="adminLogo" :menu="menu" :selected="selected" :titlePage="titlePage"/>

        <main-content class="p-2">
            <router-view @loading="loading"></router-view>
        </main-content>

        <footer-component />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
</template>

<script>
  import store from './store'
  import preloaderComponent from './components/AdminLTE/Components/preloader.vue'
  import NavBar from './components/AdminLTE/DashBoard/NavBar.vue'
  import MainSideBar from './components/AdminLTE/DashBoard/sidebar/MainSideBar.vue'
  import FooterComponent from './components/AdminLTE/Components/footer.vue'
  import MainContent from './components/AdminLTE/DashBoard/MainContent.vue'
  import logo from './assets/infinity.png'
  import adminLogo from './assets/logo.png'

  export default {
    store,
    components: {
      preloaderComponent,
      NavBar,
      MainSideBar,
      FooterComponent,
      MainContent,
    },

    data() {
      return {
        logo: logo,
        adminLogo: adminLogo,
        document: document,
        loading: false,
        navList1: [
          {name: "Documentos", url: "/"},
          {name: "Solicitações", url: "/requests"}
        ],
        menu: [
          {
            name: "Documentos", "url": "#", icon: "nav-icon fas fa-folder", selected: true,
            menu: [
              {name: "Meus Documentos", url: "/", selected: true},
            ]
          },
          {
            name: "Clientes", "url": "#", icon: "nav-icon fas fa-user-tie",
            menu: [
              {name: "Listar Clientes", url: "/clients"},
              {name: "Adicionar Cliente", url: "/newclient"},
            ]
          },
          {
            name: "Empresas", "url": "#", icon: "nav-icon fas fa-building",
            menu: [
              {name: "Lista de Empresas", url: "/companies"},
              {name: "Adicionar Empresa", url: "/newcompany"},
            ]
          },
          {
            name: "Colaboradores", "url": "#", icon: "nav-icon fas fa-users",
            menu: [
              {name: "Meus colaboradores", url: "/colaborators"},
              {name: "Novo Colaborador", url: "/newcolaborator"},
            ]
          },
          {
            name: "Departamentos", "url": "#", icon: "nav-icon fas fa-business-time",
            menu: [
              {name: "Meus Departamentos", url: "/departments"},
              {name: "Criar Departamento", url: "/newdepartment"},
            ]
          },
          {
            name: "Solicitações", "url": "#", icon: "nav-icon fas fa-bell",
            menu: [
              {name: "Todas Solicitações", url: "/requests"}
            ]
          }
        ],
      }
    },

    methods: {
      seacrh(val) {
        alert(val)
      },
    },
    watch: {
      $route (to){
        this.menu.forEach(item => {
          item.selected = false
          if (item.menu) {
            item.menu.forEach(sub => {
              sub.selected = false;
              if (sub.url == to.path) {
                sub.selected = true
                item.selected = true
              }
            })
          } else {
            if (item.url == to.path) {
              item.selected = true
            }
          }
        })
      }
    } 
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .dataTables_length {
        text-align: left;
        padding-left: 1rem;
    }
  }
</style>
