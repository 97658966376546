<template>
    <div class="col-12 p-0 m-0 py-3">
        <div class="message bg-warning card col-12" id="info" v-if="!readOnly"> Editando campos do email {{ currEmail ? currEmail:"?" }}</div>
        <loader-component v-show="loading"></loader-component>

        <!-- Modals -->
        <modal-component v-if="emails && !readOnly">
            <card-component @form-submited="closeEmails" title="Emails" card="success" action="Feito" class="col-md-8 col-sm-6">
                <form-group-component label="Data de expiracao" class="col-12 col-sm-6">
                    <VueDatePicker v-model="date"
                        :format=" date => {
                            if (date) {
                                const year = date.getFullYear()
                                let month = date.getMonth() + 1
                                if (month < 10) {
                                    month = `0${month}`
                                }
                                let data = date.getDate()
                                if (data < 10) {
                                    data = `0${data}`
                                }
                                let hours = date.getHours()
                                if (hours < 10) {
                                    hours = `0${hours}`
                                }
                                let mins = date.getMinutes()
                                if (mins < 10) {
                                    mins = `0${mins}`
                                }
                                const format = `${year}-${month}-${data} ${hours}:${mins}`
                                this.data.expire_date = format + ':00'
                                return format
                            } else {
                                this.data.expire_date= null
                            }
                        }"
                    >
                    </VueDatePicker>
                </form-group-component>
                <form-group-component label="Prazo em meses da dura&ccedil;&atilde;o do contrato" class="col-12 col-sm-6">
                    <input v-model="data.due_months" type="number" step="1" min="0" class="form-control" placeholder="desde a assinatura anterior">
                </form-group-component>
                <form-group-component label="Mensagem do e-mail" class="col-12">
                    <textarea v-model="data.message" class="form-control"></textarea>
                </form-group-component>

                <form-group-component label="Emails(separar por virgula)" class="col-12">
                    <Select2 class="my-select" v-model="data.email" :options="options" :settings="{
                            theme: 'default', 
                            multiple: 'multiple', 
                            dropdownCssClass: 'slt-p',
                            placeholder: 'Adicionar emails',
                            selectionCssClass: 'form-control'
                        }" 
                        id="input-emails" style="width: 100%" @input="onSearch" :filterable="true" @select="select" @change="onBlur"
                    ></Select2>
                </form-group-component>
            </card-component>
        </modal-component>
        <modal-component v-if="currEmail && selectedField !== null && campo.modal">
            <card-component @form-submited="() => { this.campo.modal = false }" title="Propriedades" card="primary" action="Feito" class="col-md-8 col-sm-6">
                <form-group-component v-for="item in campo.fields" :key="item.label" :label="item.label" :class="['col-md-' + item.class]">
                    <select class="form-control" v-if="item.type == 'select'" v-model="data.fields[currEmail][selectedField][item.model]">
                        <option v-for="option in item.options" :key="option.val" :value="option.val">{{ option.text }}</option>
                    </select>

                    <input :type="item.type" v-else class="form-control" v-model="data.fields[currEmail][selectedField][item.model]">
                </form-group-component>
            </card-component>
        </modal-component>
        <!-- ./Modals -->

        <div class="content d-flex row m-0 p-0 col-12 flex-wrap">
            <div class="col-12 p-2 h3" v-if="!readOnly">Campos de preenchimento e assinatura</div>
            <div class="col-12">
                <button class="btn btn-primary" @click="goTo(pages.actual - 1)">
                    <i class="fa fa-angle-left icon"></i>
                </button>
                <span class="px-3">Exibindo &aacute;gina 
                    <select v-model="pages.actual" @change="goTo(Number(pages.actual))">
                        <option v-for="i in pages.total" :key="i">{{ i }}</option>
                    </select>
                     de {{ pages.total }}</span>
                <button class="btn btn-primary" @click="goTo(pages.actual + 1)">
                    <i class="fa fa-angle-right icon"></i>
                </button>
            </div>
            <div class="pdf-toolbar order-1 order-sm-2 col-12 col-sm-3 col-lg-2
                border-left border-secondary p-1 bg-dark" v-if="!readOnly"
            >
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-warning col-12" @click="() => emails = true"> Editar Emails </button>
                </form-group-component>
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-primary col-12" @click="addField()"> <i class="fa fa-plus"></i>  Assinatura </button>
                </form-group-component>
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-primary col-12" @click="addField('text')"> <i class="fa fa-plus"></i> Campo </button>
                </form-group-component>
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-info col-12" @click="nextEmail(1)"> Próximo E-mail </button>
                </form-group-component>
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-info col-12" @click="nextEmail(-1)"> E-mail Anterior </button>
                </form-group-component>
                <form-group-component label="" class="col-3 col-sm-12 p-1">
                    <button class="btn btn-success col-12" @click="submit"> <i class="fa fa-check"></i>  Enviar </button>
                </form-group-component>
            </div>
            <div class="pdf-container-div d-flex row col-12 order-2 order-sm-1 
                p-0 m-0 justify-content-centerbg-dark" :class="{
                    'col-sm-9 col-lg-10': !readOnly,
                    'col-12': readOnly
                }"
            >
                <div ref="pdfLimit" class="d-flex align-items-center p-0 m-0 mx-auto">
                    <div class="pdf-container p-0 m-0" ref="pdfContainer">
                    </div>
                    <div class="fields-div mx-auto" ref="fieldsContainer">
                        <div v-for="email of data.email" :key="email" class="col-12" :class="{'topo': email == currEmail}">
                            <div class="col-12">
                                <field-component 
                                    v-for="field of data.fields[email]"
                                    :key="field.key"
                                    :type="field.type"
                                    :email="email == currEmail ? email:''"
                                    @change-props="changeProps"
                                    @click="() => {
                                        this.selectedField = field.key
                                    }"
                                    :width="field.width"
                                    :height="field.height"
                                    :xPos="field.xPos"
                                    :yPos="field.yPos"
                                    :my-key="field.key"
                                    v-show="field.page == pages.actual"
                                    @info="() => {
                                        if (email == this.currEmail) {
                                            this.selectedField = field.key
                                            this.campo.modal = true
                                        }
                                    }"
                                    :class="{'disabled': email != currEmail}"
                                ></field-component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '@/helpers/Api'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import ModalComponent from '@/components/ModalComponent.vue'
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import Select2 from 'vue3-select2-component'
    import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
    import FieldComponent from '@/components/FieldComponent.vue'
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'

    export default {
        name: "SingleFileView",
        data() {
            return {
                loading: true,
                date: null,
                doc: {},
                emails: true,
                readOnly: true,
                campo: {
                    modal: false,
                    fields: [
                        {type: 'text', label: 'Texto', class: '8', model: 'text'},
                        {type: 'color', label: 'Cor do texto', class: '4', model: 'color'},
                        {type: 'number', label: 'Tamanho da fonte', class: '4', model: 'fontsize'},
                        {type: 'select', label: 'Alinhamento', class: '4', model: 'align', options: [
                            {val: 'center', text: 'Centralizado'},
                            {val: 'left', text: 'Esquerda'},
                            {val: 'right', text: 'Direita'},
                        ]},
                        {type: 'select', label: 'Negrito', class: '4', model: 'bold', options: [
                            {val: null, text: 'Não'},
                            {val: 'bold', text: 'Sim'},
                        ]},
                        {type: 'select', label: 'Itálico', class: '4', model: 'italic', options: [
                            {val: null, text: 'Não'},
                            {val: 'italic', text: 'Sim'},
                        ]},
                        {type: 'select', label: 'Sublinhado', class: '4', model: 'underline', options: [
                            {val: null, text: 'Não'},
                            {val: 'underline', text: 'Sim'},
                        ]},
                        {type: 'select', label: 'Grifado', class: '4', model: 'strikethrough', options: [
                            {val: null, text: 'Nâo'},
                            {val: 'strikethrough', text: 'Sim'},
                        ]},
                    ]
                },
                currEmail: false,
                selectedField: null,
                pages: {
                    actual: null,
                    total: null
                },
                options: [],
                data: {
                    email: [],
                    document_key: null,
                    message: "",
                    expire_date: null,
                    due_months: null,
                    chain: 1,
                    width_page: null,
                    fields: []
                }
            }
        },
        components: {
            LoaderComponent,
            ModalComponent,
            CardComponent,
            FormGroupComponent,
            Select2,
            FieldComponent,
            VueDatePicker
        },
        created() {
            document.title = "Enviar solicitação de assinatura"
            GlobalWorkerOptions.workerSrc = '/js/app.worker.js'
            this.readOnly = this.$route.query.readonly == 'true'
            this.getDocument(this.$route.params.key)
        },
        methods: {
            async loadPDF() {
                try {
                    this.loading = true
                    const response = await Api.get("files/download/" + this.doc.document_key, 'blob')
                    const blob = new Blob([response.data], { type: 'application/pdf' });

                    const arrayBuffer = await blob.arrayBuffer();
                    const uint8Array = new Uint8Array(arrayBuffer);

                    // Obtendo o documento PDF a partir do Uint8Array
                    const pdf = await getDocument({ data: uint8Array }).promise;
                    window._PDF = pdf
                    this.renderPage(1);
                } catch (e) {
                    alert("Ocorreu um erro ao pegar o documento")
                    console.log(e)
                    this.loading = false
                }
            },
            async renderPage(pageNumber) {
                    window._PDF.getPage(pageNumber).then((page) => {
                        this.loading = true
                        const pdfViewer = document.createElement('div');
                        pdfViewer.className = 'pdf-viewer';

                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        const viewport = page.getViewport({ scale: 1 });

                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        this.$refs.pdfLimit.style.height = viewport.height + 'px';
                        this.$refs.pdfLimit.style.width = viewport.width + 'px';
                        this.$refs.pdfContainer.style.height = viewport.height + 'px';
                        this.$refs.pdfContainer.style.width = viewport.width + 'px';
                        this.$refs.fieldsContainer.style.width = viewport.width + 'px';
                        pdfViewer.style.height = viewport.height + 'px';
                        pdfViewer.style.width = viewport.width + 'px';

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        page.render(renderContext).promise.then(() => {
                            pdfViewer.appendChild(canvas);
                        });

                        this.$refs.pdfContainer.innerHTML = '';
                        this.$refs.pdfContainer.appendChild(pdfViewer);
                        this.pages.actual = pageNumber
                        this.pages.total = window._PDF.numPages;
                        this.loading = false
                    });
            },
            async getDocument(key) {
                try {
                    this.loading = true

                    const response = await Api.get("docs?document_key=" + key)
                    const docs = response.data.data
                    if (docs.length < 1) {
                        alert("Documento nao encontrado")
                        this.$router.push("/404")
                    } else {
                        this.doc = docs[0]
                        this.loadPDF()
                        this.emails = true
                        this.data.document_key = this.doc.document_key
                        this.loadPDF()
                    }
                } catch(e) {
                    alert("Error: \n" + e.data.error)
                    this.loading = false
                }
            },
            closeEmails() {
                this.emails = false
                this.currEmail = this.data.email[0]
            },
            onSearch(event) {
                let val = event.target.value.replace(',', '')
                if (event.data == ',') {
                    if (!this.options.includes(val) && val.length >= 5) {
                        this.options.push(val)
                    }
                    if (!this.data.email.includes(val) && val.length >= 5) {
                        this.data.email.push(val)
                    } else {
                        event.target.value = ""
                    }
                }
            },
            onBlur(event) {
                event.data = ','
                this.onSearch(event)
            },
            goTo(nextPageNumber) {
                if (nextPageNumber <= this.pages.total && nextPageNumber > 0) {
                    this.renderPage(nextPageNumber);
                } else {
                    alert("Página não existe")
                }
            },
            addField(type = "image") {
                if (this.data.email.length < 1) {
                    alert("Adicione pelo menos 1 email")
                    this.emails = true
                } else {
                    if (!this.currEmail) {
                        this.currEmail = this.data.email[0]
                    }
                    if (!this.data.fields[this.currEmail]) {
                        this.data.fields[this.currEmail] = []
                    }
                    const data = {
                        type: type,
                        key: this.data.fields[this.currEmail].length,
                        xPos: 0,
                        yPos: 0,
                        height: 55,
                        width: 160,
                        page: this.pages.actual
                    }
                    if (type == 'text') {
                        Object.assign(data, {
                            text: 'Nome Completo',
                            color: '#000000',
                            fontsize: 14,
                            bold: null,
                            align: 'left',
                            italic: null,
                            underline: null,
                            strikethrough: null
                        })
                    }
                    this.data.fields[this.currEmail].push(data)
                }
            },
            nextEmail(add) {
                const index = this.data.email.indexOf(this.currEmail)
                if (index >= 0 && this.data.email[index + add]) {
                    this.currEmail = this.data.email[index + add]
                } else {
                    alert("Sem proximo, adicione")
                }
            },
            changeProps(key, props, email) {
                if (email != '') {
                    for (const prop in props) {
                        this.data.fields[email][key][prop] = props[prop]
                    }
                }
            },
            select(data) {
                if (!data.selected) {
                    // email removido
                    const email = data.id
                    this.currEmail = this.data.email[0] ? this.data.email[0]:"?"
                    delete this.data.fields[email]
                }
            },
            submit() {
                const fields = []
                for(const email of this.data.email) {
                    fields.push(this.data.fields[email])
                }
                this.data.width_page = this.$refs.pdfContainer.clientWidth

                const data = {}
                Object.assign(data, this.data)
                data.fields = fields

                this.sendRequest(data)
            },
            async sendRequest(data) {
                try {
                    this.loading = true
                    const response = await Api.post("requests/documents", data);

                    alert(response.data.message)
                    
                    this.$router.push("/requests")
                } catch(e) {
                    let alerta = e.data.error ? e.data.error:e.data.message
                    alert("Error: \n" + alerta)
                    let message = "";
                    for(let prop in e.data.errors) {
                        message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                    console.log(e, data)
                } finally {
                    this.loading = false
                } 
            }
        }
    }
</script>
<style>
    /* Estilo para o container do select2 */
    .slt-p {
        z-index: 2000 !important;
    }
    /* Estilos para a toolbar do PDF */
    .pdf-container-div {
        border: 2px solid blue;
        overflow: scroll;
    }

    .pdf-container-div > div {
        position: relative;
    }
    .fields-div, .fields-div > div, .fields-div > div > div {
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 0px;
        margin: 0px;
    }
    .fields-div > div > div {
        position: relative !important;
    }
    .fields-div > div.topo {
        z-index: 4;
    }

    .pdf-toolar {
        display: flex;
        justify-content: start;
        align-content: start;
        align-items: start;
        flex-wrap: wrap;
    }
    div#info.message {
        position: fixed;
        bottom: 10px;
        z-index: 1024;
        filter: opacity(0.8);
    }
    .disabled {
        pointer-events: none !important;
        cursor: none !important;
        filter: opacity(0.7) !important;
        border: none;
    }
</style>