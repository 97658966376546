<template>
    <div class="view col-12 table-responsive py-5">
        <loader-component v-show="loading" />
        <data-table class="col-12 table table-striped tabe-bordered display" :data="team" :columns="columns"
            :options="{ responsive: true, autoWidth: false, language: 
                {
                    search: 'Pesquisa', zeroRecords: 'Sem colaboradores para exibir',
                    info: 'Exibindo _START_ - _END_ de _TOTAL_ colaboradores',
                    infoEmpty: '',
                    infoFiltered: '(filtrados de _MAX_ colcaboradores)',
                    lengthMenu: 'Exibir _MENU_ cadastros',
                    paginate: {first: 'Primeira', last: '&Uacute;ltimo', next: 'Próxima', previous: 'Anterior'}
                }
            }"
        >
        <thead>
            <tr class="table-primary">
            <th>#</th>
            <th>Nome</th>
            <th>Sobrenome</th>
            <th>E-mail</th>
            <th>Permiss&otilde;es</th>
            <th>Ver mais</th>
            </tr>
        </thead>
        </data-table>
    </div>
</template>

<script>
  import Api from '@/helpers/Api.js'
  import DataTable from 'datatables.net-vue3'
  import DataTableLib from 'datatables.net-bs5'
  import ButtonsHtml5 from 'datatables.net-buttons-bs5'
  import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'

  DataTable.use(DataTableLib)
  DataTable.use(ButtonsHtml5)
  // DataTable.use(pdfmake)

  export default {
    name: "ColaboratorsView",
    components: {
      DataTable,
      LoaderComponent
    },
    data() {
      return {
        loading: true,
        team: null,
        columns: [
          {data: 'id'},
          {data: 'name'},
          {data: 'last_name'},
          {data: 'email'},
          {data: null, render: (data) => {
            let str = ""
            for(let permission of data.permissions) {
                str += "*" + permission + " "
            }
            return str
          }},
          {data: null, render: (data) => `<a href='/colaborator/${data.id}' class='btn bnt-primary'><i class='fa fa-eye'></i></a>`},
        ]
      }
    },

    created() {
      document.title = "Colaboradores"
      this.getUsers()
    },

    methods: {
      async getUsers() {
        try {
          this.loading = true
          const response = await Api.get("teams");
          this.team = response.data.data
        } catch(e) {
          alert("Error: \n" + e.data.error)
        } finally {
          this.loading = false
        }     
      }
    }
  }
</script>

<style scoped>
  @import 'datatables.net-bs5';
</style>