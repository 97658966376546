<template>
    <div class="d-flex row flex-wrap col-12">
        <!-- MODALS -->
        <modal-component v-show="modal.newDoc" id="new-doc-modal">
            <card-component class="col-12 col-md-8 col-lg-6" title="Novo documento" @form-submited="sendFile" action="Enviar Documento" card="success" 
                @second-action="modalVal('newDoc',false)" :action2="{text: 'Fechar', class: 'danger'}">
                <form-group-component label="Nome do documento" class="col-12">
                    <input type="text" v-model="newDoc.name" class="form-control">
                </form-group-component>
                <form-group-component label="Documento" class="col-12" @change="changeFile">
                    <input type="file" class="form-control">
                </form-group-component>
                <form-group-component label="Melhorar o documento"  class="col-12">
                    <select v-model="newDoc.optimizer" class="form-control form-select">
                        <option value='1'>Sim</option>
                        <option value='0'>Nao</option>
                    </select>
                </form-group-component>
            </card-component>
        </modal-component>

        <modal-component v-show="modal.newFolder" id="new-folder-modal">
            <card-component class="col-12 col-md-8 col-lg-6" title="Nova Pasta" @form-submited="createFolder" action="Criar Pasta" card="primary" 
                @second-action="modalVal('newFolder',false)" :action2="{text: 'Fechar', class: 'danger'}">
                <form-group-component label="Nome da pasta" class="col-12">
                    <input type="text" v-model="newFolder.name" class="form-control">
                </form-group-component>
                <form-group-component label="Acessibilidade"  class="col-12">
                    <select class="form-control form-select" @change="(evt) => {
                        this.newFolder.accessibility = evt.target.value
                    }">
                        <option v-for="val in newFolder.accessibilities" :key="val.id" :value="val.id">{{ val.val }}</option>
                    </select>
                </form-group-component>
            </card-component>
        </modal-component>

        <modal-component v-show="modal.key" id="doc-key-modal">
            <card-component class="col-12 col-md-8 col-lg-6" title="chave do documento" @form-submited="getDoc" action="procurar" card="info" 
                @second-action="modalVal('key',false)" :action2="{text: 'Fechar', class: 'danger'}">
                <form-group-component label="Chave do documento" class="col-12">
                    <input type="text" v-model="docKey" class="form-control">
                </form-group-component>
            </card-component>
        </modal-component>
        <!-- ./MODALS -->

        <div class="col-12 icons toolbar d-flex flex-wrap row m-0 p-1 border-secondary border-bottom">
            <div class="col-5 p-0 text-left">
                <ul class="list-inline">
                    <li class="nav-item p-0 list-inline-item" v-for="path in paths" :key="path">
                        <a href="#" class="nav-link p-0">{{ path.folder }} ></a>
                    </li>
                </ul>
            </div>

            <div class="d-flex row flex-wrap col-7 p-0 justify-content-end">
                <div class="col-auto p-0 px-2 m-0" v-for="btn in buttons" :key="btn.model">
                    <button class="btn d-flex flex-row" :class="['btn-' + btn.card]" @click="modalVal(btn.model ,true)">
                        <i class="fa mt-1" :class="btn.icon"></i>
                        <span class="d-none d-sm-inline pl-2">{{ btn.text }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="col-4 col-md-3 col-lg-2 p-2 pt-2 m-0" @click="back">
            <div class="icon-box d-flex row justify-content-center">
                <i class="fa acces d-flex fa-sync" v-if="paths.length <= 1"></i>
                <i class="fa acces d-flex fa-reply" v-else></i>
                <i class="fa fa-5x text-info fa-folder"></i>
            </div>
            <span class="file-info col-12 text-center">...</span>
        </div>
        <div class="col-4 col-md-3 col-lg-2 p-2 pt-2 m-0" @contextmenu.prevent="click(file)" @click="click(file)"
            v-for="file in files" :key="file.id">
            <div class="icon-box d-flex row justify-content-center" title="Clique para ver opcoes">
                <i class="fa acces d-flex" :class="icons[file.accessibility]"></i>
                <i class="fa fa-5x text-primary" :class="['fa-' +  (file.name ? 'file':'folder')]"></i>
            </div>
            <span class="file-info col-12 text-center">{{ file.name ? file.name:file.folder }}{{ file.extension ? '.'+file.extension:''}}</span>
        </div>
    </div>
</template>

<script>
    import ModalComponent from './ModalComponent.vue';
    import FormGroupComponent from './AdminLTE/Components/FormGroupComponent.vue';
    import CardComponent from './AdminLTE/Components/CardComponent.vue';

    export default {
        name: "FilesManagerComponent",
        components: {
            ModalComponent,
            FormGroupComponent,
            CardComponent
        },
        props: {
            files: Array,
            paths: Array
        },
        emits: ['upload', 'mkdir', 'on-select', 'back', 'open', 'get-doc'],
        data() {
            return {
                modal: {
                    key: false,
                    newFolder: false,
                    newDoc: false
                },
                newDoc: {
                    name: null,
                    file: null,
                    optimizer: '0'
                },
                newFolder: {
                    name: null,
                    accessibility: 'OnlyMe',
                    accessibilities: [
                        {id: 'OnlyMe', val: 'Apenas eu'}, 
                        {id: 'Everyone', val: 'Qualquer um'},
                        {id: 'Departments', val: 'Meus departamentos'}
                    ]
                },
                buttons: [
                    {text: 'Documento', icon: 'fa-upload', model: 'newDoc', card: 'success'},
                    {text: 'Pasta', icon: 'fa-plus', model: 'newFolder', card: 'primary'},
                    {text: 'Chave', icon: 'fa-key', model: 'key', card: 'info'},
                ],
                icons: {
                    'Everyone': 'fa-eye',
                    'Only Me': 'fa-lock',
                    'Departments': 'fa-business-time'
                },
                docKey: null
            }
        },
        mounted() {
            this.newFolder.accessibility = this.newFolder.accessibilities[1]
        },
        methods: {
            modalVal(key, val) {
                this.modal[key] = val
            },
            changeFile(event) {
                this.newDoc.file = event.target.files[0];
            },
            sendFile() {
                if (this.paths.length > 1) {
                    this.newDoc.folder = this.paths[this.paths.length - 1].id
                }
                this.$emit('upload', this.newDoc)
                this.modalVal('newDoc', false)
            },
            createFolder() {
                if (this.paths.length > 1) {
                    this.newFolder.folder = this.paths[this.paths.length - 1].id
                }
                this.$emit('mkdir', this.newFolder)
            },
            click(file, open = false) {
                if (!open) {
                    this.$emit('on-select', file)
                } else {
                    this.$emit('on-select', file, true)
                }
            },
            back() {
                this.$emit('back')
            },
            getDoc() {
                this.$emit('get-doc', this.docKey)
            }
        }
    }
</script>

<style scoped>
    .icon-box {
        position: relative;
    }
    /* *:has(> .icon-box):hover {
        background: rgba(20, 30, 89, 0.7);
    } */
    .icon-box .acces {
        position: absolute;
        margin: auto;
        z-index: 2;
        top: 10px;
        bottom: 0px;
        align-items: center;
        color: white;
    }
    .icon-box .tool-bar {
        left: 50%;
    }
</style>