<template>
    <div class="view col-12 table-responsive p-2 py-5">
        <modal-component v-if="remind.show">
            <card-component card="warning" title="Enviar Lembrete" 
                action="Enviar" class="col-11 col-md-8 col-sm-7"
                :action2="{class: 'secondary', text: 'Fechar'}"
                @second-action="() => {this.remind.show = false; this.remind.signing_key = null}"
                @form-submited="reminder"
            > 
                <form-group-component label="Enviar por" class="col-12">
                    <select class="form-control" v-model="remind.type">
                        <option value="email">Email</option>
                        <option value="whatsapp">Whatsapp</option>
                        <option value="sms">SMS</option>
                    </select>
                </form-group-component>
                <form-group-component :label="remind.type == 'email' ? 'Mensagem':'Celular'" class="col-12">
                    <input type="text" v-model="remind.phone" v-if="remind.type != 'email'" class="form-control">
                    <textarea v-model="remind.message" class="form-control" v-if="remind.type == 'email'"></textarea>
                </form-group-component>
            </card-component>
        </modal-component>
        <loader-component v-show="loading" />
        <data-table class="col-12 table table-striped tabe-bordered display" :data="requests" :columns="columns"
            :options="{ responsive: true, autoWidth: false, language: 
                {
                    search: 'Pesquisa', zeroRecords: 'Sem solicita&ccedil;&otilde;es para exibir',
                    info: 'Exibindo _START_ - _END_ solicita&ccedil;&otilde;es',
                    infoEmpty: '',
                    infoFiltered: '(filtrados de _MAX_ solicita&ccedil;&otilde;es)',
                    lengthMenu: 'Exibir _MENU_ cadastros',
                    paginate: {first: 'Primeira', last: '&Uacute;ltimo', next: 'Próxima', previous: 'Anterior'}
                }
            }"
        >
            <thead>
                <tr class="table-primary">
                <th>Envio</th>
                <th>Data de envio</th>
                <th>Actualizacao</th>
                <th>Estado</th>
                <th>Expira</th>
                <th>Accoes</th>
                </tr>
            </thead>
        </data-table>
    </div>
</template>

<script>
    import Api from '@/helpers/Api.js'
    import DataTable from 'datatables.net-vue3'
    import DataTableLib from 'datatables.net-bs5'
    import ButtonsHtml5 from 'datatables.net-buttons-bs5'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import ModalComponent from '@/components/ModalComponent.vue'
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'

    DataTable.use(DataTableLib)
    DataTable.use(ButtonsHtml5)
    // DataTable.use(pdfmake)

    export default {
        name: "RequestsView",
        components: {
            DataTable,
            LoaderComponent,
            ModalComponent,
            CardComponent,
            FormGroupComponent
        },
        data() {
            return {
                loading: true,
                requests: null,
                remind: {
                    type: 'email',
                    phone: null,
                    message: null,
                    signing_key: null,
                    show: false
                },
                columns: [
                    {data: null, render: (data) => `
                        <b>Remetente: </b>${data.sender.name}<br>
                        <b>Signatario: </b>${data.email}
                    `},
                    {data: 'send_time'},
                    {data: 'update_time'},
                    {data: 'status'},
                    {data: 'expire_date'},
                    {data: null, render: data => `
                        <div class='row m-0 p-0 justify-content-between flex-nowrap'>
                            <a href='/doc/${data.document}?readonly=true' class='btn btn-success m-1 d-inline'><i class='fa fa-eye'></i></a>
                            <button class='btn btn-warning m-1 d-inline btn-remind' data-signing-key='${data.signing_key}'><i class='fa fa-bell btn-remind' data-signing-key='${data.signing_key}'></i></button>
                            <button class='btn btn-danger m-1 d-inline btn-delete' data-signing-key='${data.signing_key}'><i class='fa fa-trash btn-delete' data-signing-key='${data.signing_key}'></i></button>
                        </div>
                    `},
                ]
            }
        },

        created() {
            document.title = "Solicitacoes"
            this.getRequests()
        },
        mounted() {
            // Add click event to the delete buttons
            this.$el.querySelector('.dataTables_wrapper').addEventListener('click', this.handleClick);
        },
        methods: {
            async getRequests() {
                try {
                    this.loading = true
                    const response = await Api.get("requests");
                    this.requests = response.data.data
                } catch(e) {
                    alert("Error: \n" + e.data.error)
                } finally {
                    this.loading = false
                }     
            },
            handleClick(event) {
                if (event.target.classList.contains('btn-delete')) {
                    const signingKey = event.target.getAttribute('data-signing-key');
                    this.delete(signingKey);
                } else if (event.target.classList.contains('btn-remind')) {
                    const signingKey = event.target.getAttribute('data-signing-key');
                    this.remind.signing_key = signingKey
                    this.remind.show = true
                }
            },
            async delete(signing_key) {
                const cancel = confirm("Deseja realmente cancelar a solicitacao?")
                if (cancel) {
                    try {
                        this.loading = true
                        const response = await Api.post("requests/cancelled?signing_key=" + signing_key, {})
                        const message = response.data.message
                        alert(message)
                    } catch(e) {
                        alert("Erro:\n" + e.data.message)
                        console.log(e)
                    } finally {
                        this.getRequests()
                    }
                }
            },
            async reminder() {
                try {
                    this.loading = true
                    const response = await Api.post(`reminders/${this.remind.type}?signing_key=${this.remind.signing_key}&message=${this.remind.message}&phone=${this.remind.phone}`, {})
                    const message = response.data.message
                    alert(message)
                } catch(e) {
                    alert(e.data.message)
                    console.log(e)
                } finally {
                    this.loading = false
                    this.remind.show = false
                }
            }
        }
    }
</script>

<style scoped>
    @import 'datatables.net-bs5';
</style>