<template>
    <card-component @form-submited="postData" title="Novo Departamento" action="cadastrar departamento" card="warning">
        <loader-component v-show="loading" />
        <form-group-component v-for="input in inputs" :key="input.model" :label="input.label" :class="[input.class, input.model]">
            <input :type="input.type" v-model="department[input.model]" class="form-control new-department-input" :placeholder="input.placeholder"  :id="['input-'+input.model]">
        </form-group-component>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import Api from '@/helpers/Api.js'
    import $ from 'jquery'

    export default {
        name: "NewDepartmentView",
        data() {
            return {
                loading: false,
                department: {
                    name: '',
                    email: '',
                },
                inputs: [
                    {type: 'text', label: 'Nome', model: 'name', class: 'col-md-7', placeholder: 'Nome do Departamento'},
                    {type: 'email', label: 'E-mail', model: 'email', class: 'col-md-5', placeholder: 'E-mail'},
                ]
            }
        },
        created() {
            document.title = "Novo Departamento"
        },
        components: {
            CardComponent,
            FormGroupComponent,
            LoaderComponent
        },
        methods: {
            async postData() {
                try {
                    this.loading = true
                    $('.new-department-input').removeClass('is-invalid')
                    const response = await Api.post("departments", this.department);
                    // this.companies = response
                    alert(response.data.message)
                    this.$router.push("departments")
                } catch(e) {
                    let alerta = e.data.error ? e.data.error:e.data.message
                    alert("Error: \n" + alerta)
                    if (e.data.errors) {
                        let message = "";
                        for(let prop in e.data.errors) {
                            $(`#input-${prop}`).addClass('is-invalid')
                            message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                        }
                        alert(message)
                    }
                } finally {
                    this.loading = false
                }     
            }
        }
    }
</script>