<template>
    <!-- Preloader -->
    <div class="preloader flex-column justify-content-center align-items-center">
        <img class="animation__shake" :src="logo" alt="AdminLTELogo">
    </div>
</template>

<script>
export default {
    name: "preloader-component",
    props: {
        logo: String
    },
    data() {
        return {

        }
    }
}
</script>