<template>
    <div class="col-12">
        <loader-component v-show="loading" />
        <!-- Modals -->
        <modal-component v-show="modals.folder">
            <card-component title="Informações da pasta" class="col-md-8 col-sm-6" card="primary" action="Atualizar dados" 
            :action2="{text: 'Excluir pasta', class: 'danger'}" @second-action="deleteFolder" @form-submited="updateFolder">
                <form-group-component label="id" class="col-2">
                    <div class="form-control">{{ selectedFile.id }}</div>
                </form-group-component>
                <form-group-component label="Nome" class="col-10">
                    <input class="form-control" v-model="selectedFile.folder">
                </form-group-component>
                <form-group-component label="Acessibilidade" class="col-md-3">
                    <select class="form-control" v-model="selectedFile.accessibility">
                        <option>Only Me</option>
                        <option>Departments</option>
                        <option>Everyone</option>
                    </select>
                </form-group-component>
                <form-group-component label="Subpastas" class="col-md-3">
                    <div class="form-control">{{ selectedFile.sub_folders ? selectedFile.sub_folders.length:0 }}</div>
                </form-group-component>
                    <form-group-component label="criada em" class="col-md-6">
                    <div class="form-control">{{ selectedFile.created_on }}</div>
                </form-group-component>
                <form-group-component class="p-2">
                    <button class="btn btn-secondary mr-2" @click="modal('folder', false)" type="button">
                        Cancelar
                    </button>
                    <button class="btn btn-success ml-2" @click="openFolder(false)" type="button">
                        Abrir pasta
                    </button>
                </form-group-component>
            </card-component>
        </modal-component>

        <modal-component v-show="modals.file">
            <card-component title="Informações do documento" class="col-md-8" card="success" action="Enviar requisição" 
            :action2="{text: 'Excluir documento', class: 'danger'}" @second-action="deleteFile" @form-submited="getDoc(selectedFile.document_key)">
                <form-group-component label="id" class="col-2">
                    <div class="form-control">{{ selectedFile.id }}</div>
                </form-group-component>

                <form-group-component label="Nome" class="col-10 col-md-7">
                    <div class="form-control">{{selectedFile.name}}</div>
                </form-group-component>
                <form-group-component label="Estado" class="col-md-3">
                    <div class="form-control">{{ selectedFile.status }}</div>
                </form-group-component>

                <form-group-component label="publicado por" class="col-md-6">
                    <div class="form-control">
                        {{ selectedFile.uploaded_by ? selectedFile.uploaded_by.name:"" }}
                        {{ selectedFile.uploaded_by ? selectedFile.uploaded_by.last_name:""  }}
                    </div>
                </form-group-component>
                <form-group-component label="Template:" class="col-md-2">
                    <div class="form-control">{{ selectedFile.is_template }}</div>
                </form-group-component>
                <form-group-component label="Em" class="col-md-4">
                    <div class="form-control">{{ selectedFile.uploaded_on }}</div>
                </form-group-component>
                <form-group-component label="Chave" class="col-md-10">
                    <div class="form-control">{{ selectedFile.document_key }}</div>
                </form-group-component>
                <form-group-component label="Chave" class="col-md-2">
                    <button class="btn btn-secondary form-control" @click="copy(selectedFile.document_key)" type="button">
                        <i class="fa fa-copy"></i> 
                        Copiar
                    </button>
                </form-group-component>

                <form-group-component label="" class="p-2 col-md-3 col-lg-2">
                    <button class="btn btn-primary form-control" @click="download" type="button"> 
                        <i class="fa fa-download"></i> Baixar 
                    </button>
                </form-group-component>
                <form-group-component label="" class="p-2 col-md-3">
                    <button class="btn btn-primary form-control" @click="getDoc(selectedFile.document_key, true)" type="button"> 
                        <i class="fa fa-eye"></i> Visualizar 
                    </button>
                </form-group-component>
                <form-group-component label="" class="p-2 col-md-2">
                    <button class="btn btn-warning form-control" @click="modal('file', false)" type="button"> Cancelar </button>
                </form-group-component>
            </card-component>
        </modal-component>
        <!-- ./modals -->
        <files-manager-component :files="files" @upload="upload" @mkdir="mkdir" @on-select="onSelect" 
        :paths="paths" @back="back" @get-doc="getDoc"/>
    </div>
</template>

<script>
  import Api from '@/helpers/Api.js'
  import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
  import FilesManagerComponent from '@/components/FilesManagerComponent.vue'
  import ModalComponent from '@/components/ModalComponent.vue'
  import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
  import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'

  export default {
    name: "HomeView",
    components: {
        LoaderComponent,
        FilesManagerComponent,
        ModalComponent,
        CardComponent,
        FormGroupComponent
    },
    data() {
        return {
            loading: false,
            selectedFile: {},
            modals: {
                folder: false,
                file: false
            },
            paths: [{folder: "Fonte", id: false}],
            files: []
        }
    },

    created() {
        document.title = "Meus Documentos"
        this.getFiles()
    },

    methods: {
        async upload(doc) {
            try {
                this.loading = true
                const fd = new FormData()
                fd.append('name', doc.name)
                if (doc.optimizer == '1') {
                    fd.append('optimizer', '1')
                }
                fd.append('file', doc.file, doc.file.name)
                if (doc.folder) { 
                    fd.append('folder', doc.folder); 
                }
                const response = await Api.post('files/upload', fd, true)
                alert(response.data.message)
                this.getFiles()
            } catch(e) {
                let alerta = e.data.error ? e.data.error:e.data.message
                alert("Error: \n" + alerta)
                if (e.data.errors) {
                    let message = "";
                    for(let prop in e.data.errors) {
                        message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                }
                this.loading = false
            }
        },
        async mkdir(dir) {
            try {
                this.loading = true
                const response = await Api.post('folders', dir)
                alert(response.data.message)
                this.getFiles()
            } catch(e) {
                let alerta = e.data.error ? e.data.error:e.data.message
                alert("Error: \n" + alerta)
                if (e.data.errors) {
                    let message = "";
                    for(let prop in e.data.errors) {
                        message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                }
                this.loading = false
            }
        },
        async getFiles() {
            try {
                this.loading = true
                const folder = this.paths[this.paths.length - 1].id

                const folders = await Api.get("folders" + (folder ? "?id=" + folder:""))
                if (folder) {
                    if (folders.data.data.length > 0) {
                        this.files = folders.data.data[0].sub_folders
                    } else {
                        this.files = []
                    }
                } else {
                    this.files = folders.data.data
                }
                const response = await Api.get("docs" + (folder ? "?folder=" + folder:""))
                
                if (!folder) {
                    for(let file of response.data.data) {
                        if (file.folder == 'Source') {
                            this.files.push(file)
                        }
                    }
                } else {
                    this.files = this.files.concat(response.data.data)
                }
            } catch(e) {
                alert("Error: \n" + e.data.error)
            } finally {
                this.loading = false
            }     
        },
        modal(key, val) {
            this.modals[key] = val
        },
        onSelect(file, open = false) {
            this.selectedFile = file
            if (!file.name) {
                // folder
                if (open) {
                    this.openFolder(file)
                } else {
                    this.modals.folder = true
                }
            } else {
                // folder
                if (open) {
                    this.getDoc(file.document_key)
                } else {
                    this.modals.file = true
                }
            }
        },
        async deleteFolder() {
            try {
                this.loading = true
                const response = await Api.delete('folders/' + this.selectedFile.id)
                alert(response.data.message)
                this.getFiles()
            } catch(e) {
                let alerta = e.data.error ? e.data.error:e.data.message
                alert("Error: \n" + alerta)
                if (e.data.errors) {
                    let message = "";
                    for(let prop in e.data.errors) {
                    message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                }
                this.loading = false
            } finally {
                this.modals.folder = false
            }
        },
        async updateFolder() {
            try {
                this.loading = true
                const params = `?name=${this.selectedFile.folder}&accessibility=${this.selectedFile.accessibility.replace(' ', '')}`
                const response = await Api.put('folders/' + this.selectedFile.id + params)
                alert(response.data.message)
                this.getFiles()
            } catch(e) {
                let alerta = e.data.error ? e.data.error:e.data.message
                alert("Error: \n" + alerta)
                if (e.data.errors) {
                    let message = "";
                    for(let prop in e.data.errors) {
                        message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                }
                this.loading = false
            } finally {
                this.modals.folder = false
            }
        },
        openFolder(folder = false) {
            if (!folder) {
                folder = this.selectedFile
            }
            this.paths.push(folder)
            this.getFiles()
            this.modals.folder = 0
        },
        back() {
            if (this.paths.length > 1) {
                this.paths.pop()
            }
            this.getFiles()
            this.modals.folder = 0
        },
        getDoc(key, readOnly = false) {
            this.$router.push("/doc/" + key + '?readonly=' + readOnly)
        },
        async copy(text) {
            await navigator.clipboard.writeText(text)
            alert("copiado")
        },
        async deleteFile() {
            try {
                this.loading = true
                const response = await Api.delete('docs?id=' + this.selectedFile.id)
                alert(response.data.message)
                this.getFiles()
            } catch(e) {
                let alerta = e.data.error ? e.data.error:e.data.message
                alert("Error: \n" + alerta)
                if (e.data.errors) {
                    let message = "";
                    for(let prop in e.data.errors) {
                    message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                    }
                    alert(message)
                }
                this.loading = false
            } finally {
                this.modals.file = false
            }
        },
        async download() {
            try {
                this.loading = true

                const response = await Api.get("files/download/" + this.selectedFile.document_key, 'blob')
                
                const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a')
                link.href = urlBlob
                link.setAttribute('download', this.selectedFile.document_key + "." + this.selectedFile.extension)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)

                this.modals.file = false
                this.loading = false
            } catch(e) {
                alert("Ocorreu um erro no download")
            } finally {
                this.loading = false
            }     
        }
    }
  }
</script>