<template>
    <card-component @form-submited="putData" :title="company.name + ' - ' + company.id" action="Atualizar dados" card="primary">
        <loader-component v-show="loading" />
        <form-group-component v-for="input in inputs" :key="input.model" :label="input.label" :class="[input.class, input.model]">
            <input :type="input.type" v-model="company[input.model]" class="form-control new-company-input" 
                :placeholder="input.placeholder"  :id="['input-'+input.model]" :name="input.model" :readonly="input.readOnly">
        </form-group-component>

        <form-group-component class="col-md-3" label="Status">
            <select2 v-model="company.status" :options="status" :settings="{theme: 'default'}" 
                id="input-status" style="width: 100%"></select2>
        </form-group-component>

        <form-group-component class="col-md-7" label="token de acesso">
            <input type="text" v-model="company.token" class="form-control" id="input-token" readonly>
        </form-group-component>
        <form-group-component class="col-md-2" label="copiar">
            <button class="btn btn-warning col-12" type="button" @click="copy">
                <i class="fa fa-copy mr-2"></i>
                <span>Copiar</span>
            </button>
        </form-group-component>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import Select2 from 'vue3-select2-component';
    import Api from '@/helpers/Api.js'
    import $ from 'jquery'

    export default {
        name: "SingleCompanyView",
        data() {
            return {
                loading: false,
                company: {
                    name: '-',
                    id: null,
                    cnpj: '-',
                    email: '-',
                    phone: '-',
                    token: '-',
                    address: '-',
                    status: 'Active'
                },
                status: ['Active', 'Inactive', 'Suspended'],
                inputs: [
                    {type: 'text', label: 'Nome', model: 'name', class: 'col-md-7', placeholder: 'Nome da empresa'},
                    {type: 'email', label: 'E-mail', model: 'email', class: 'col-md-5', placeholder: 'E-mail', readOnly: true},
                    {type: 'string', label: 'Celular', model: 'phone', class: 'col-md-4', placeholder: 'Celular'},
                    {type: 'cnpj', label: 'CNPJ', model: 'cnpj', class: 'col-md-4', placeholder: 'cnpj'},
                    {type: 'text', label: 'Endereço', model: 'address', class: 'col-md-4', placeholder: 'endereço da empresa'},
                ]
            }
        },
        created() {
            document.title = "Empresa"
            this.getCompany()
        },
        mounted() {
        },
        components: {
            CardComponent,
            FormGroupComponent,
            LoaderComponent,
            Select2
        },
        methods: {
            copy() {
                // Copy the text inside the text field
                navigator.clipboard.writeText(this.company.token);
                alert('Token copiado')
            },
            async getCompany() {
                try {
                    this.loading = true
                    const response = await Api.get("companies/" + this.$route.params.id);
                    if (response.data.data) {
                        this.company = response.data.data
                        this.company.token = this.company.access ? this.company.access.token:null
                    } else {
                        alert("Empresa nao encontrada")
                        this.$router.push("/companies")
                    }
                } catch(e) {
                    alert("Error: \n" + e.data.error)
                    this.$router.push("/companies")
                } finally {
                    this.loading = false
                }  
            },
            async putData() {
                try {
                    this.loading = true
                    $('.new-company-input').removeClass('is-invalid')

                    const response = await Api.put("companies/" + this.company.id, this.company);
                    alert(response.data.message)
                    this.$router.push("/companies")
                } catch(e) {
                    let alerta = e.data.error ? e.data.error:e.data.message
                    alert("Error: \n" + alerta)
                    if (e.data.errors) {
                        let message = "";
                        for(let prop in e.data.errors) {
                            $(`#input-${prop}`).addClass('is-invalid')
                            message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                        }
                        alert(message)
                    }
                } finally {
                    this.loading = false
                }     
            },
        }
    }
</script>