<template>
    <div class="form-group">
        <label for="">{{ label }}</label>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "FormGroupComponent",
        props: {
            label: String
        }        
    }
</script>