import { createRouter, createWebHistory } from 'vue-router'

import NotFoundView from '../views/NotFoundView.vue'
import Homeview from '@/views/HomeView.vue'
import CompaniesView from '@/views/CompaniesView.vue'
import NewCompanyViewVue from '@/views/NewCompanyView.vue'
import SingleCompanyView from '@/views/SingleCompanyView.vue'
import ClientsViews from '@/views/ClientsView.vue'
import SingleClientView from '@/views/SingleClientView.vue'
import NewClientView from '@/views/NewClientView.vue'
import NewDepartmentView from '@/views/NewDepartmentView.vue'
import DepartmentsView from '@/views/DepartmentsView.vue'
import NewColaboratorView from '@/views/NewColaboratorView.vue'
import ColaboratorsView from '@/views/ColaboratorsView.vue'
import SingleColaboratoView from '@/views/SingleColaboratorView.vue'
import SingleFileView from '@/views/SingleFileView.vue'
import RequestsView from '@/views/RequestsView.vue'

const routes = [
  {
    path: '/',
    name: 'Documentos',
    component: Homeview
  },
  {
    path: '/doc/:key',
    name: 'SendRequest',
    component: SingleFileView
  },
  {
    path: '/companies',
    name: 'Empresas',
    component: CompaniesView
  },
  {
    path: '/company/:id',
    name: 'Empresa',
    component: SingleCompanyView
  },
  {
    path: '/newcompany',
    name: 'Nova Empresa',
    component: NewCompanyViewVue
  },
  {
    path: '/clients',
    name: 'Clientes',
    component: ClientsViews
  },
  {
    path: '/client/:id',
    name: 'Cliente',
    component: SingleClientView
  },
  {
    path: '/newclient',
    name: 'Novo cliente',
    component: NewClientView
  },
  {
    path: '/departments',
    name: 'Departamentos',
    component: DepartmentsView
  },
  {
    path: '/newdepartment',
    name: 'Novo Departamento',
    component: NewDepartmentView
  },
  {
    path: '/colaborators',
    name: 'Colaboradores',
    component: ColaboratorsView
  },
  {
    path: '/colaborator/:id',
    name: 'Colaborador',
    component: SingleColaboratoView
  },
  {
    path: '/newcolaborator',
    name: 'Novo Colaborador',
    component: NewColaboratorView
  },
  {
    path: '/requests',
    name: 'Solicitacoes',
    component: RequestsView
  },

  // not found
  { path: '/:pathMatch(.*)*', component: NotFoundView }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
