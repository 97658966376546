<template>
    <div class="my-modal col-12 justify-content-center row align-items-center p-0 m-0" :id="id">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ModalComponent",
    props: {
        id: String
    }
}
</script>

<style scoped>
    .my-modal {
        height: 100vh;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 1060;
        background: rgba(200, 200, 200, 0.8);
    }
    .my-modal > * {
        position: fixed;
        top: 0;
        bottom: 0;
        /* left: 0; */
        right: 0;
        margin: auto;
        display: none;
    }
</style>