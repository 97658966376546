<template>
    <card-component @form-submited="postData" title="Novo Cliente" action="Cadastrar Cliente" card="warning">
        <loader-component v-show="loading" />
        <form-group-component v-for="input in inputs" :key="input.model" :label="input.label" :class="[input.class, input.model]">
            <input :type="input.type" v-model="client[input.model]" class="form-control new-client-input" :placeholder="input.placeholder"  :id="['input-'+input.model]">
        </form-group-component>
    </card-component>
</template>

<script>
    import CardComponent from '@/components/AdminLTE/Components/CardComponent.vue'
    import FormGroupComponent from '@/components/AdminLTE/Components/FormGroupComponent.vue'
    import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'
    import Api from '@/helpers/Api.js'
    import $ from 'jquery'

    export default {
        name: "NewClientView",
        data() {
            return {
                loading: false,
                client: {
                    name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    password: '',
                    address: '',
                },
                inputs: [
                    {type: 'text', label: 'Nome', model: 'name', class: 'col-md-4', placeholder: 'Nome do cliente'},
                    {type: 'text', label: 'Sobrenome', model: 'last_name', class: 'col-md-4', placeholder: 'Sobrenome do cliente'},
                    {type: 'email', label: 'E-mail', model: 'email', class: 'col-md-4', placeholder: 'Email'},
                    {type: 'string', label: 'Celular', model: 'phone', class: 'col-md-4', placeholder: 'Celular do cliente'},
                    {type: 'password', label: 'Senha', model: 'password', class: 'col-md-4', placeholder: 'Senha do cliente'},
                    {type: 'text', label: 'Endereço', model: 'address', class: 'col-md-4', placeholder: 'Endereço do cliente'},
                ]
            }
        },
        created() {
            document.title = "Novo cliente"
        },
        components: {
            CardComponent,
            FormGroupComponent,
            LoaderComponent
        },
        methods: {
            async postData() {
                try {
                    this.loading = true
                    $('.new-client-input').removeClass('is-invalid')
                    const response = await Api.post("customers", this.client);
                    // this.companies = response
                    alert(response.data.message)
                    this.$router.push("clients")
                } catch(e) {
                    let alerta = e.data.error ? e.data.error:e.data.message
                    alert("Error: \n" + alerta)
                    if (e.data.errors) {
                        let message = "";
                        for(let prop in e.data.errors) {
                            $(`#input-${prop}`).addClass('is-invalid')
                            message += `=>[${prop}]: ${e.data.errors[prop][0]}.\n`
                        }
                        alert(message)
                    }
                } finally {
                    this.loading = false
                }     
            }
        }
    }
</script>