<template>
    <div class="error-page">
        <h2 class="headline text-warning">404</h2>

        <div class="error-content">
          <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Pagina n&atilde;o encontrada.</h3>

          <p>
            Não foi possível encontrar a página que você estava procurando.
            Enquanto isso, você pode <router-link to="/">voltar ao Inicio</router-link> ou tentar voltar a pagina anterior.
          </p>

          <button class="btn btn-lg btn-warning" @click="back"> Voltar a pagina Anterior </button>
        </div>
        <!-- /.error-content -->
      </div>
</template>

<script>
    export default {
        name: "NotFoundView",
        mounted() {
          document.title = "404 - Not Found"
          document.l = 908
        },
        methods: {
          back() {
            history.back()
          }
        }
    }
</script>