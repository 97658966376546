<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link :to="home_url" class="brand-link">
            <img :src="logo" alt="Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
            <span class="brand-text font-weight-light">{{ text }}</span>
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- SidebarSearch Form -->
            <div class="form-inline">
                <div class="input-group" data-widget="sidebar-search">
                    <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-sidebar">
                            <i class="fas fa-search fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <!-- Add icons to the links using the .nav-icon class
                        with font-awesome or any other icon font library -->
                    <li  class="nav-item" :class="{'menu-open': item.selected}" v-for="item in menu" :key="item.url">
                        <router-link :to="item.url" class="nav-link d-flex flex-direction-row justify-content-between px-1" :class="{'active': item.selected}">
                            <sapn class="">
                                <i :class="item.icon"></i>
                                <p>
                                    {{ item.name }}
                                </p>
                            </sapn>
                            <i class="right fas fa-angle-left m-0 p-0" v-if="item.menu != undefined"></i>
                        </router-link>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" v-for="subitem in item.menu" :key="subitem.url">
                                <router-link :to="subitem.url" class="nav-link d-flex flex-direction-row justify-content-start" :class="{'active': subitem.selected}">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>{{ subitem.name }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
    </aside>
</template>

<script>
export default {
    name: "MainSideBar",
    props: {
        home_url: String,
        text: String,
        logo: String,
        menu: Array,
        selected: String
    },
    data() {
        return {
            icon_class: "nav-icon fas "
        }
    },
}
</script>