import { createStore } from 'vuex'

export default createStore({
  state: {
    title: "PlugSign",
    loading: false
  },
  getters: {
  },
  mutations: {
    changeTitle(state, val) {
      state.title = val
    }
  },
  actions: {
  },
  modules: {
  }
})
