import axios from 'axios'

// Esta chave pode vir de um .env ou da session storage, sei la...
const key = "RxZzZoDttRDAVsybC5yUzKZ6p0sXfjnjU8yhItg4rcLVA9KbFl5iRCDXhDE3eeZQv1kC01yONR4658kmBN3PsTWGCFvHWaNqjKYj7ANY2pxlNf8qTaMhgaMiGOTowUKqVwYLVT51EzL4xO5C165xuUZKwZ8btRNp3BVc14GVWm8zWSGaqh2iOYvGfu6MP2I4bVONpTEDpGzhuktizlBnIxpRaUAIClGFP6K6eOz2rViRNXELnybNASftDhvJtbpj8rbZVOPH8bAlDe6FeWLXCH8dlD7lfaJFeOYZoTDhH0q1F49hyHX21p3b9mBSXoNyi2ucqaeoKum234kHY";

const Api = {
    key: key,
    baseUrl: "https://app.plugsign.com.br/api/",
    headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${key}`,
    },
    get(url, response = 'json') {

        return new Promise((resolve, reject) => {
            axios.get(`${this.baseUrl}${url}`, {
                responseType: response,
                headers: this.headers 
            })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },
    post(url, data, formData = false) {
        return new Promise((resolve, reject) => {
            if (formData) {
                this.headers['Content-Type'] = 'multipart/form-data'
            }
            axios.post(`${this.baseUrl}${url}`, data, { headers: this.headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },
    put(url, data, formData = false) {
        return new Promise((resolve, reject) => {
            if (formData) {
                this.headers['Content-Type'] = 'multipart/form-data'
            }
            axios.put(`${this.baseUrl}${url}`, data, { headers: this.headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },
    delete(url) {
        return new Promise((resolve, reject) => {
            axios.delete(`${this.baseUrl}${url}`, { headers: this.headers })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
        });
    },
}

export default Api;