<template>
  <div class="view col-12 table-responsive py-5">
    <loader-component v-show="loading" />
    <data-table class="col-12 table table-striped tabe-bordered display" :data="companies" :columns="columns"
        :options="{ responsive: true, autoWidth: false, language: 
            {
                search: 'Pesquisa', zeroRecords: 'Sem empresas para exibir',
                info: 'Exibindo _START_ - _END_ de _TOTAL_ empresas',
                infoEmpty: '',
                infoFiltered: '(filtrados de _MAX_ empresas)',
                lengthMenu: 'Exibir _MENU_ cadastros',
                paginate: {first: 'Primeira', last: '&Uacute;ltimo', next: 'Próxima', previous: 'Anterior'}
            }
        }"
    >
      <thead>
        <tr class="table-primary">
          <th>#</th>
          <th>Nome da empresa</th>
          <th>E-mail</th>
          <th>Telefone</th>
          <th>CNPJ</th>
          <th>Ver mais</th>
        </tr>
      </thead>
    </data-table>
  </div>
</template>

<script>
  import Api from '../helpers/Api.js'
  import DataTable from 'datatables.net-vue3'
  import DataTableLib from 'datatables.net-bs5'
  import ButtonsHtml5 from 'datatables.net-buttons-bs5'
  import LoaderComponent from '@/components/AdminLTE/Components/LoaderComponent.vue'

  DataTable.use(DataTableLib)
  DataTable.use(ButtonsHtml5)
  // DataTable.use(pdfmake)

  export default {
    name: "CompaniesView",
    components: {
      DataTable,
      LoaderComponent
    },
    data() {
      return {
        loading: true,
        companies: null,
        columns: [
          {data: 'id'},
          {data: 'name'},
          {data: 'email'},
          {data: 'phone'},
          {data: 'cnpj'},
          {data: null, render: (data) => `<a href='/company/${data.id}' class='btn bnt-primary'><i class='fa fa-eye'></i></a>`}
        ]
      }
    },

    created() {
      document.title = "Empresas"
      this.getCompanies()
    },

    methods: {
      async getCompanies() {
        try {
          this.loading = true
          const response = await Api.get("companies");
          // this.companies = response
          this.companies = response.data.data
        } catch(e) {
          alert("Error: \n" + e.data.error)
        } finally {
          this.loading = false
        }     
      }
    }
  }
</script>

<style scoped>
  @import 'datatables.net-bs5';
</style>