<template>
    <div
        ref="draggableDiv"
        class="draggable"
        :style="{ width: width + 'px', height: height + 'px', transform: `translate(${xPos}px, ${yPos}px)` }"
    >
        <div 
            class="position-relative p-0 m-0 top-0"
            style="width: 100%; height: 100%;"
        >
            <i class="fa fa-info-circle position-absolute text-warning fa-2x" v-if="type != 'image'" @click="onInfo"></i>
            <img :src="img[type]" style="width: 100%; height: 100%;"/>
        </div>
    </div>
</template>
  
<script>
    import interact from 'interactjs';
    import img1 from '@/assets/fill.png'
    import img2 from '@/assets/sign.png'
  
    export default {
        props: {
            parent: String,
            email: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'image'
            },
            xPos: {
                type: Number,
                default: 0
            },
            yPos: {
                type: Number,
                default: 0
            },
            width: {
                type: Number,
                default: 150
            },
            height: {
                type: Number,
                default: 55
            },
            myKey: Number,
        },
        emits: ['change-props', 'info'],
        data() {
            return {
                img: {
                    'text':  img1,
                    'image': img2
                },
                proporcao: 8/3
            };
        },
        mounted() {
            if (this.type == 'image') {
                this.$emit('change-props', this.email)
            }
            this.initInteract();
        },
        methods: {
            initInteract() {
                const edges =  {
                    top: true,
                    left: true,
                    bottom: true,
                    right: true,
                }
                if (this.type == 'image') {
                    edges.top = false
                    edges.bottom = false
                }
                interact(this.$refs.draggableDiv).draggable({
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: 'parent',
                            endOnly: true,
                        }),
                    ],
                    onmove: event => {
                        this.$emit('change-props', this.myKey, {
                            xPos: this.xPos + event.dx,
                            yPos: this.yPos + event.dy
                        }, this.email)
                        console.log(this.myKey)
                    },
                }).resizable({
                    edges: edges,
                    onmove: event => {
                        const target = event.target;
                        const width = parseFloat(target.style.width) + event.deltaRect.width;
                        let height = parseFloat(target.style.height) + event.deltaRect.height;

                        if (this.type == 'image') {
                            height = width / this.proporcao
                        }      
                        this.$emit('change-props', this.myKey, {
                            height: height,
                            width: width
                        })
                    },
                });
            },
            onInfo() {
                this.$emit('info', this.myKey)
            }
        },
    };
</script>
  
<style>
    .draggable {
        position: absolute;
        border: 1px solid rgb(29, 191, 255);
        top: 0;
        left: 0;
    }

    .draggable .fa {
        top: 1px;
        right: 1px;
        cursor: pointer;
    }
</style>
